import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import BlogTag from "../components/BlogTag";
import { useLocation } from '@reach/router'

import {GatsbyImage, getImage} from "gatsby-plugin-image";

import MyPortableText from "../components/MyPortableText";
import JobList from "../components/JobList";
import Head from "../components/Head";


export const query = graphql`
query ($slug: String) {
  sanityServices(slug: {current: {eq: $slug}}) {
    title
    color
    imageleft {
      asset {
        gatsbyImageData
      }
    }
    imageright {
      asset {
        gatsbyImageData
      }
    }
    testimonial {
      text
      title
      ort
    }
    serviceendetext
    service {
      text
      title
      image {
        asset {
          gatsbyImageData
        }
      }
    }
    marqueetext
   
   
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  } 
}
    `;

const ServiceContent = styled.div`
    .service__landing__container{
            background-color: #${({color}) => (color ? color : "ffffff")}; 
    }
`;

const Service = ({data}) => {
    const service = data.sanityServices;
    return (
        <Layout settings={data.allSanitySettings.edges[0].node} landing={true} subfolder={true}>
            <Head title={service.title}/>

            <ServiceContent color={service.color}>
                <div className="service__landing__container">
                    <div className="service__landing__image__wrapper">
                        <GatsbyImage image={service.imageleft.asset.gatsbyImageData} alt={service.title}></GatsbyImage>
                        <GatsbyImage image={service.imageright.asset.gatsbyImageData} alt={service.title}></GatsbyImage>
                        <Marquee gradient={false} className="marquee__landing service" speed={40}>
                            <div className="marqueeText white">
                                <span className="marqueeText__inner uppercase">{service.title}</span>
                                <span className="marqueeText__inner uppercase">{service.title}</span>
                                <span className="marqueeText__inner uppercase">{service.title}</span>
                                <span className="marqueeText__inner uppercase">{service.title}</span>
                                <span className="marqueeText__inner uppercase">{service.title}</span>
                                <span className="marqueeText__inner uppercase">{service.title}</span>
                            </div>
                        </Marquee>
                    </div>
                        <a href={'#overview'}  className="arrowdown__container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73">
                            <g id="Group_103" data-name="Group 103" transform="translate(-924 -975)">
                                <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(924 975)" class="svgoutline">
                                    <circle cx="36.5" cy="36.5" r="36.5" stroke="none"/>
                                    <circle cx="36.5" cy="36.5" r="35" fill="none"/>
                                </g>
                                <g id="Group_12" data-name="Group 12" transform="translate(655 945.5)">
                                    <g id="Group_13" data-name="Group 13">
                                        <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(305 50)" class="svgarrow"/>
                                        <path id="Path_36" data-name="Path 36" d="M1.061,14.086l11.59,11.59,11.591-11.59" transform="translate(292.349 53)" class="svgarrow"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
                <Marquee gradient={false} className="marqueeWrapper fixedHeight" speed={40}>
                    <div className="marqueeText color-orange">
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                    </div>
                </Marquee>

                <div className="services__container" id={"overview"}>
                    {service.service.map(el => (
                        <div className="service__wrapper">
                            {console.log(el)}
                            <GatsbyImage alt={el.title} image={el.image.asset.gatsbyImageData} className="service__image"></GatsbyImage>
                            <div className="service__text__container">
                                <p className="large">{el.title}</p>
                                <p className="small">{el.text}</p>
                            </div>

                        </div>

                    ))}

                </div>
                <div className="service__ending">
                    <p className="large">{service.serviceendetext}</p>
                </div>
                <div className="service__testimonial__container color-whiteonblack">
                    <div className="service__testi__left">
                        <p className="large einzelservice">{service.testimonial.text}</p>
                        <br/>
                        <p className="small bold">{service.testimonial.title}</p>
                        <p className="small bold">{service.testimonial.ort}</p>
                    </div>
                    <div className="service__testi__right arrowbtn__wrapper">
                        <Link to={'/service'} className="btn arrowbtn bluegrey smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">PHOTOVOLTAIK PLANEN</p>
                        </Link>
                        <Link to={'/#energierechner'} className="btn arrowbtn green smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">PERS. STROMPREISE BERECHNEN</p>
                        </Link>
                        <Link to={'/kontakt'} className="btn arrowbtn pink smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">TERMIN VEREINBAREN</p>
                        </Link>
                        <Link to={'/blog'} className="btn arrowbtn orange smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">GRÜNER BLOG / GRÜNES LEBEN</p>
                        </Link>
                    </div>
                </div>
            </ServiceContent>
        </Layout>
    )
}

export default Service;
